@import '../variables';

body {
  background-color: map-get($vi-black, 100);

  .mat-drawer-content {
    min-height: 100vh;
  }

  mat-sidenav-container.mat-drawer-container {
    background-color: map-get($vi-black, 100);
  }
}
