@import '../../variables';

@mixin mixin-mat-select-decorations {
  [mat-select-top-label] {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  // Description under Mat-Select
  [mat-select-height-small],
  [mat-select-height-medium],
  [mat-select-height-large],
  [mat-select-frameless] {
    & .mat-mdc-select ~ [mat-select-description] {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      left: 12px;
      top: 4px;
    }
  }

  .mat-mdc-form-field-type-mat-select.mat-form-field-disabled
    .mat-mdc-select
    ~ [mat-select-description] {
    color: map-get($color, battleship-grey);
  }

  .mat-mdc-select.ng-valid:not(.ng-pristine) ~ [mat-select-description] {
    color: map-get($color, jet);
  }

  // Mat-Select Underline
  .mat-mdc-form-field-type-mat-select .mdc-line-ripple {
    display: none !important;
  }

  // Mat-Select Panel
  .mat-mdc-select-panel {
    border-radius: $radius;
  }

  // Mat-Select Expand Arrow
  [mat-select-height-small],
  [mat-select-height-medium] {
    .mat-mdc-select-arrow-wrapper {
      transform: translateY(0%) !important;
    }
  }

  [mat-select-height-large] {
    .mat-mdc-select-arrow-wrapper {
      transform: translateY(-6px) !important;
    }
  }

  //arrow
  .mat-mdc-select {
    &.mat-mdc-select-empty {
      mat-select-trigger {
        top: 1px;
      }
    }
  }

  [mat-select-height-large] .mat-mdc-select {
    &.mat-mdc-select-empty {
      mat-select-trigger {
        top: 2px;
      }
    }
  }
  .mat-mdc-select .mat-mdc-select-arrow {
    width: 0;
    border: 0;
    margin: 0 4px;
    font-size: 22px;

    &::before {
      font-family: 'Material Icons';
      content: '\e5cf';
      position: relative;
      top: -12px;
      right: 12px;
    }

    svg {
      display: none;
    }
  }

  [mat-select-frameless] .mat-mdc-select .mat-mdc-select-arrow {
    &::before {
      right: 6px;
    }
  }

  [mat-select-frameless] .mat-mdc-select.mat-select-disabled .mat-mdc-select-arrow {
    &::before {
      right: 10px;
    }
  }

  // Mat-Select Label
  [mat-select-height-small],
  [mat-select-height-medium] {
    & .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      -webkit-transform: none;
      transform: none;
      padding-top: 0;
      color: map-get($color, gunmetal-grey);
      left: 12px;
    }

    &:not(.mat-form-field-hide-placeholder) {
      & .mdc-floating-label--float-above {
        display: none;
      }
    }
  }

  // Mat-Select Text field
  [mat-select-height-small],
  [mat-select-height-medium],
  [mat-select-height-large] {
    .mdc-text-field {
      padding: 0;
    }

    .mat-mdc-form-field-infix {
      min-height: auto;
    }
  }

  // Mat-Select Font size
  [mat-select-height-small],
  [mat-select-height-medium],
  [mat-select-height-large] {
    & .mat-mdc-floating-label,
    .mat-mdc-select {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
