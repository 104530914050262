@import '../../variables';

@mixin mixin-mat-textarea-decorations {
  // Description under mat-textarea
  [mat-textarea] + [mat-textarea-description] {
    font-size: 12px;
    position: relative;
    left: 18px;
    top: 4px;
  }

  [mat-textarea] .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  [mat-textarea] .mdc-line-ripple {
    display: none;
  }

  [mat-textarea] {
    &:has([mat-textarea-limit]) {
      & .mat-mdc-text-field-wrapper {
        padding-bottom: 20px;
        border-radius: 4px;
      }
    }
  }

  // Limit Counter
  [mat-textarea] [mat-textarea-limit] {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 8px;
    font-size: 12px;
  }
}
