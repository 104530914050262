@import '../variables';
@import './select/select-states.scss';
@import './select/select-small.scss';
@import './select/select-medium.scss';
@import './select/select-large.scss';
@import './select/select-decorations.scss';

// Developer Notes:
// https://www.figma.com/file/Jy9TzBemPbTky9RAUGKqX5/Interaction-Components
//
// mixin-mat-select-states contains css for all states a mat-select can be in eg. hover, filled, disabled, error
// small-height, medium and large override some of the styles used in the default mixin
//
// Selectors often descend from either mat-form-field or mat-select to access data neccasary for proper display of mat-select
//
// Important note: When mat-select does not have value, a placeholder(label) is not rendered inside mat-select but as a sibling of it in DOM

// Custom mat-form-field attributes
// mat-select-height-small
// mat-select-height-medium
// mat-select-height-large - label visible alongside value

// mat-select sibling elements attributes
// span[mat-select-description] - description of mat-select

body {
  @include mixin-mat-select-states();
  @include mixin-mat-select-small-height();
  @include mixin-mat-select-medium-height();
  @include mixin-mat-select-large-height();
  @include mixin-mat-select-decorations();
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  width: auto !important;
}
