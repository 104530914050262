@mixin media-only($point) {
  @if $point == xlarge {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 1084px) and (max-width: 1279px) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 768px) and (max-width: 1083px) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: 575px) and (max-width: 767px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: 575px) {
      @content;
    }
  }
}

@mixin media-down($point) {
  @if $point == large {
    @media (max-width: 1279px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 1083px) {
      @content;
    }
  } @else if $point == small {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: 575px) {
      @content;
    }
  }
  // No media queries for large devices (> 1279px)
}

@mixin media-up($point) {
  @if $point == xlarge {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 1084px) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: 576px) {
      @content;
    }
  }
  // No media queries for extra smallall devices (< 576px)
}

@mixin height-down($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin width-down($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media (max-width: 767px) and (max-height: 420px) {
    @content;
  }
}

@mixin mobile-portrait() {
  @media (max-width: 420px) and (max-height: 767px) {
    @content;
  }
}
