@import '../variables';

body {
  mat-dialog-container.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: 0;
      border-radius: $unit;
    }

    mat-card-header {
      .mat-mdc-card-header-text {
        width: 100%;
      }
    }

    mat-dialog-content.mdc-dialog__content {
      padding: 0;
    }

    mat-card-actions.mat-mdc-card-actions {
      display: block;
    }
  }

  .confirm-dialog {
    max-width: calc(100vw - 12px) !important;
    max-height: 98vh !important;

    @include media-down(xsmall) {
      width: calc(100vw - 12px) !important;
    }
  }
}
