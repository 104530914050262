@import '../variables';

body {
  .mat-mdc-tab-nav-bar {
    height: 5 * $unit;
    border-bottom: 0 none;
  }

  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: map-get($color, eclipse);
    height: 3px;
  }

  mat-tab-header.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(map-get($color, black), 0.12);
  }

  .mat-mdc-tab-link:focus {
    background-color: inherit !important;
  }

  .mat-ink-bar {
    background-color: map-get($color, white);
  }

  .mat-mdc-tab-link {
    text-transform: uppercase;
    height: 5 * $unit;
    opacity: 1;
    color: map-get($color, gunmetal);
    font-weight: 500;
    transition: color 0.15s, background-color 0.15s;

    &:hover {
      background-color: rgba(map-get($color, black), 0.1);
    }

    &.active {
      color: map-get($color, eclipse);
      pointer-events: none;
    }
  }

  .active-link {
    opacity: 1;
  }

  .mdc-tab--active {
    opacity: 1;
  }
}
