@import '../../variables';

@mixin mixin-mat-select-small-height {
  [mat-select-height-small] {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 13px;
    }
    .mat-mdc-select {
      padding: 7px 12px;
    }
  }
}
