@import 'src/projects/ui/styles/common';

body {
  .mat-mdc-button:not([matsuffix]),
  .mat-mdc-raised-button:not([matsuffix]),
  .mat-mdc-unelevated-button:not([matsuffix]),
  .mat-mdc-outlined-button:not([matsuffix]) {
    &.app-large-button {
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
    }

    &.app-small-button {
      line-height: 32px;
      padding: 0 16px;
      border-radius: 16px;

      .mat-icon {
        width: 21px;
        height: 21px;
        line-height: 21px;
        font-size: 18px;
      }
    }
  }

  .mat-mdc-outlined-button.mat-accent:not([matsuffix]) {
    border-color: map-get($color, battleship-grey);
  }

  // appEllipsis
  .app-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  // appText directive
  .app-text {
    font-weight: normal;

    &.app-text-1 {
      font-size: 16px;
      line-height: 24px;
    }

    &.app-text-2 {
      font-size: 14px;
      line-height: 24px;
    }

    &.app-text-3 {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
