@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'common';
@import 'theme';
@import 'shadows';
@import 'material/button';
@import 'material/card';
@import 'material/common';
@import 'material/dialog';
@import 'material/form-field';
@import 'material/menu';
@import 'material/select';
@import 'material/icon';
@import 'material/input';
@import 'material/sidenav';
@import 'material/slide-toggle';
@import 'material/snackbar';
@import 'material/tabs';
@import 'material/toolbar';
@import 'material/tooltip';
@import 'material/textarea';
@import 'material/checkbox';

$font-family: Inter, sans-serif !default;

* {
  margin: 0;
  padding: 0;
}

body {
  color: map-get($color, gunmetal);
  line-height: 24px;
}

html,
body,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
g,
text,
div {
  font-family: $font-family !important;
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip-list {
  white-space: pre-line;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

mat-select,
mat-option {
  display: inline-block;
  width: 100%;
}
