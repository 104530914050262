@import '../../variables';

@mixin mixin-mat-textarea-states {
  @include mixin-mat-textarea-state-initial();
  @include mixin-mat-textarea-state-hovered();
  @include mixin-mat-textarea-state-filled();
  @include mixin-mat-textarea-state-disabled();
  @include mixin-mat-textarea-state-error();
  @include mixin-mat-textarea-state-frameless();
}

@mixin mixin-mat-textarea-state-initial {
  [mat-textarea] {
    [mat-textarea-limit] + textarea {
      overflow: hidden;
    }
  }

  [mat-textarea] {
    &.mat-mdc-form-field {
      border: 1px solid map-get($color, light-grey);
      border-radius: 4px;
      background-color: map-get($color, white);
    }

    & textarea {
      color: map-get($color, davys-grey);
    }

    & [mat-textarea-limit] {
      color: map-get($color, davys-grey);
    }
  }

  [mat-textarea] + [mat-textarea-description] {
    color: map-get($color, davys-grey);
  }
}

@mixin mixin-mat-textarea-state-hovered {
  [mat-textarea].mat-mdc-form-field:hover:not(.mat-mdc-form-field-disabled):not(.has-error):not(.mat-focused),
  [mat-textarea].mat-form-field.has-content:not(.mat-form-field-disabled):not(.has-error):not(.mat-focused) {
    border: 1px solid map-get($color, battleship-grey);

    & textarea {
      color: map-get($color, jet);
    }

    & [mat-textarea-limit] {
      color: map-get($color, jet);
    }
  }

  [mat-textarea].mat-mdc-form-field:hover:not(.mat-form-field-disabled):not(.has-error):not(.mat-focused) {
    & + [mat-textarea-description] {
      color: map-get($color, jet);
    }

    & [mat-textarea-limit] {
      color: map-get($color, jet);
    }
  }
}

@mixin mixin-mat-textarea-state-filled {
  [mat-textarea].mat-mdc-form-field.mat-focused {
    & textarea {
      color: map-get($color, black);
    }

    & [mat-textarea-limit] {
      color: map-get($color, black);
    }
  }

  [mat-textarea].mat-mdc-form-field.has-content:not(.has-error):not(.mat-form-field-disabled) {
    border: 1px solid map-get($color, black);
  }

  [mat-textarea].mat-mdc-form-field.mat-focused:not(.has-error),
  [mat-textarea].mat-mdc-form-field.has-content:not(.has-error):not(.mat-form-field-disabled) {
    & + [mat-textarea-description] {
      color: map-get($color, black);
    }

    & [mat-textarea-limit] {
      color: map-get($color, black);
    }
  }
}

@mixin mixin-mat-textarea-state-disabled {
  [mat-textarea] {
    &.mat-form-field-disabled {
      border: 1px solid map-get($color, cultured-light);
      background: map-get($color, cultured-light);
      cursor: default;

      & textarea {
        color: map-get($color, battleship-grey);

        &:hover {
          color: map-get($color, battleship-grey);
        }
      }

      & [mat-textarea-limit] {
        color: map-get($color, battleship-grey);

        &:hover {
          color: map-get($color, battleship-grey);
        }
      }

      &:hover {
        border: 1px solid map-get($color, cultured-light);
      }
    }
  }

  [mat-textarea].mat-form-field-disabled + [mat-textarea-description] {
    color: map-get($color, battleship-grey);
  }
}

@mixin mixin-mat-textarea-state-error {
  [mat-textarea] {
    &.has-error {
      border: 1px solid map-get($vi-material-colors, error);

      &:hover {
        border: 1px solid map-get($vi-material-colors, error);
      }
    }
  }

  [mat-textarea].has-error + [mat-textarea-description] {
    color: map-get($vi-material-colors, error);
  }
}

@mixin mixin-mat-textarea-state-frameless {
  [mat-textarea-frameless] {
    &.mat-mdc-form-field {
      border: 1px solid transparent !important;

      & textarea {
        color: map-get($color, davys-grey);
      }
    }
  }
}
