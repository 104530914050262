@import 'src/assets/styles/inter.scss';
@import 'src/assets/styles/material-icons.scss';

// Vitoguide UI - shared everest components styles
// contain Angular Material theming, mixins and variables
@import 'src/projects/ui/styles/styles';
@import 'flags.scss';

@import '../app/shared-ui/common-ui/common-ui.scss';
@import '../projects/ui/styles/drag-drop.scss';
@import './modals.scss';
@import './module-styling.scss';

@include media-up(small) {
  html.cdk-global-scrollblock {
    top: 0 !important;
    position: initial;
    overflow-y: initial;
  }
}

body {
  overscroll-behavior: none;

  &.body-scrollblock {
    overflow-y: hidden;
  }

  vi-item {
    div {
      user-select: auto !important;
    }
  }

  vi-toolbar.app-toolbar {
    position: fixed;
    width: 100%;
    display: block;
    z-index: 100;
    top: 0;

    mat-toolbar.mat-toolbar {
      min-height: 56px !important;
      max-height: 56px !important;
    }
  }

  mat-sidenav-content.vi-page > .full-width {
    margin-top: 56px;
    min-height: calc(100vh - 56px);

    @include media-down(medium) {
      min-height: calc(100vh - 56px);
    }

    @include media-down(small) {
      margin-top: 56px;
      min-height: calc(100vh - 56px);
    }
  }
}

html.cdk-global-scrollblock,
.cdk-global-scrollblock {
  overflow-y: hidden;
}

*:not(mat-icon) {
  font-family: $font-family !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  overflow: hidden;
  margin: 0;
  color: map-get($color, black);
  font-weight: 500;
}

h1 {
  font-size: 32px;
  line-height: 44px;

  @include media-down(small) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-size: 24px;
  line-height: 32px;

  @include media-down(small) {
    font-size: 20px;
    line-height: 28px;
  }
}

h3 {
  font-size: 20px;
  line-height: 28px;

  @include media-down(small) {
    font-size: 18px;
    line-height: 26px;
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;

  @include media-down(small) {
    font-size: 16px;
    line-height: 24px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;

  @include media-down(small) {
    font-size: 14px;
    line-height: 20px;
  }
}

h6 {
  font-size: 14px;
  line-height: 20px;

  @include media-down(small) {
    font-size: 12px;
    line-height: 18px;
  }
}

vi-logo {
  cursor: pointer;
}

.mat-datepicker-content-touch {
  margin: 0 !important;
}

.check-user-loader {
  position: absolute;
  top: 120px;
}

mat-snack-bar-container {
  .mdc-snackbar__surface {
    padding: 0;
    width: 100%;
    box-shadow: none !important;
  }

  .mat-mdc-snack-bar-label {
    padding: 0;
  }
}

mat-option.mat-mdc-option {
  .mat-pseudo-checkbox-minimal {
    display: none;
  }
}

mat-paginator {
  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label,
  .mat-mdc-paginator-page-size-value {
    font-size: 12px;
    color: map-get($color, mercury);
  }
  .mat-mdc-paginator-page-size {
    align-items: center !important;
  }

  .mat-mdc-paginator-page-size-select {
    width: 56px;
  }

  .mat-mdc-select-value-text {
    font-size: 12px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: 36px !important;
  }

  .mat-mdc-select {
    border-radius: 4px;
    border: 1px solid map-get($color, light-grey);

    &:hover {
      border: 1px solid map-get($color, battleship-grey);
      border-radius: 4px;
    }
    .mat-mdc-select-trigger {
      bottom: 1px;
    }
  }
}

mat-progress-bar.post-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  display: none;
}

.block-ui {
  mat-progress-bar.post-loader {
    display: block !important;
  }

  * {
    pointer-events: none !important;
    cursor: default;
  }
}

[mat-button].icon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  min-width: 50px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.54);

  &.active {
    color: map-get($color, eclipse);
  }
}

//

*:focus {
  outline: 0px;
}

.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  max-width: calc(100vw - 45px) !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.periods-dialog,
.select-days-dialog,
.installer-registration-dialog,
.user-registration-dialog,
.datepicker-dialog,
.change-password-modal,
.heating-circuit-ignored-dialog,
.missed-hc-assignment-dialog,
.type-code-dialog,
.commissioning-add-device-dialog,
.commissioning-edit-room-dialog,
.commissioning-add-room-dialog,
.commissioning-manage-room-dialog,
.commissioning-all-added-devices-dialog,
.commissioning-all-added-rooms-dialog,
.documents-updated-dialog,
.refrigerant-circuit-history-dialog,
.edit-serial-number-dialog,
.installations-announcement-banner,
.firmware-update-dialog,
.cancel-booking-dialog,
.partner-access-share-device-dialog,
.commissioning-delete-device-confirm-dialog {
  max-width: calc(100vw - 12px) !important;
  max-height: 98vh !important;

  @include media-down(xsmall) {
    width: calc(100vw - 12px) !important;
  }
}

.datepicker-dialog {
  mat-dialog-container {
    border-radius: 8px;
  }
}

.qr-scanner-dialog {
  width: 100vw !important;
  height: 100% !important;
  max-width: 100vw !important;
  max-height: 100% !important;
  position: fixed !important;
  top: 0;
  left: 0;
}

.black-backdrop {
  background-color: map-get($color, jet);
}

.gateway-invitation-list-dialog {
  @include media-down(xsmall) {
    max-width: calc(100vw - 12px) !important;
    width: calc(100vw - 12px) !important;
  }
}

// Viessmann Apps Menu
.mat-mdc-menu-panel.app-switcher-panel {
  position: absolute;
  right: 56px;
  min-width: 310px;

  @include media-down(xsmall) {
    right: 40px;
  }

  .mat-mdc-menu-content {
    .mat-mdc-menu-item-text {
      flex: none;
    }

    .menu-item {
      a {
        font-size: 14px;
        color: map-get($color, gunmetal);
        text-decoration: none;
        // height: 19px;
        line-height: 1.36;
      }

      .menu-row {
        height: 19px;
      }

      &.mat-mdc-menu-item {
        height: auto;
        line-height: 1.36;
        padding: 7px 12px 7px 28px;

        @include media-down(xsmall) {
          padding: 9px 12px 9px 20px;
        }

        &.top {
          // height: 19px;
          line-height: 1.36;
          padding: 0px 12px 0px 33px;

          @include media-down(xsmall) {
            padding: 0px 12px 0px 25px;
          }

          a {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .icon-item-content {
        margin-left: -15px;
      }

      .icon-item-border {
        margin-left: -30px;
        margin-right: -25px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.1);
        padding: 0px 25px 0 15px;
      }
    }
  }

  mat-divider.mat-divider.menu-divider {
    margin: 10px 0;
  }
}

.mt-10 {
  margin-top: 10px;
}

mat-sidenav-container {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0);
  }
}

mat-sidenav {
  &.mat-drawer:not(.mat-drawer-side) {
    box-shadow: 4px 0 8px -4px rgba(0, 0, 0, 0.08), 8px 0 16px -8px rgba(0, 0, 0, 0.08),
      16px 0 32px -16px rgba(0, 0, 0, 0.08), inset 0 1px 0 0 #dedede;
  }

  &.mat-drawer {
    top: 56px;
    position: fixed;
    overflow: hidden;

    @include media-down(small) {
      top: 56px;
      bottom: 0;
      width: 100%;
    }

    &.mat-drawer-end {
      position: fixed;
      bottom: 0;
      box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%),
        0px 6px 30px 5px rgb(0 0 0 / 12%);
    }
  }
}

.month-datepicker,
.year-datepicker {
  .mat-calendar-period-button {
    pointer-events: none;
  }

  .mat-calendar-arrow {
    display: none;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}

.feature-grid-container {
  display: grid;
  grid-auto-flow: column dense;
  grid-column-gap: 16px;
}

.mat-mdc-button-base.cdk-program-focused .mat-button-focus-overlay {
  display: none;
}

// example - wrap vdd names in extended parameters
.cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper mat-option {
  word-break: break-word;
}

.highcharts-tooltip.trending-chart-tooltip .highcharts-label > span,
.highcharts-tooltip.trending-chart-tooltip > span {
  visibility: visible !important;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 8px;
}

svg .trending-chart-tooltip {
  display: none;
}
