@import 'src/projects/ui/styles/common';

// Styles applied for overlay elements - imported by app.scss in the end

.kanban-columns-menu .mat-menu-content {
  min-height: 64px;
  display: flex;
  align-items: center;
}

.dashboard-filter-list-panel {
  @include media-up(medium) {
    max-height: calc(100vh - 300px) !important;
  }
}

.kanban-tile-dots-menu {
  .mat-mdc-menu-item-text {
    padding-left: 16px;
  }

  .mat-mdc-menu-submenu-icon {
    left: 16px;
  }

  .mat-mdc-menu-item-text {
    font-weight: 600 !important;
  }
}
