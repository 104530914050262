@import '../../variables';

@mixin mixin-mat-input-medium-height {
  [mat-input-height-medium] {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 20px;
    }

    .mat-mdc-input-element {
      padding: 14px 12px;
    }
  }
}
