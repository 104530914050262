@import '../variables';

body {
  a {
    color: map-get($color, black);
    text-decoration: underline;
    &:hover {
      color: map-get($color, davys-grey);
    }
    &.disabled {
      pointer-events: none;
      text-decoration: none;
      color: map-get($color, light-grey);
    }
    &.link-focus:focus {
      outline: 2px auto map-get($color, light-grey);
      outline-offset: 5px;
    }
  }
}
