@use "@angular/material" as mat;

@mixin box-shadow-1 {
  box-shadow: $shadow-1 !important;
}
@mixin box-shadow-2 {
  box-shadow: $shadow-1 !important;
}

.box-shadow-1 {
  @include box-shadow-1();
}

.box-shadow-2 {
  @include box-shadow-2();
}

mat-card.mat-mdc-card {
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
}

mat-expansion-panel.mat-expansion-panel {
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
}
