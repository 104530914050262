@import 'src/projects/ui/styles/common';

//main page
.assistant-body {
  .vi-content-box {
    @include media-down(small) {
      padding: 0 24px !important;
    }
  }
}

.selection__parent {
  .mat-mdc-optgroup-label .mdc-list-item__primary-text {
    width: 100%;
  }
}

.assistant-filter-list-panel {
  overflow: auto !important;
}
