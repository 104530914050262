@import '../../variables';

@mixin mixin-mat-input-states {
  @include mixin-mat-input-state-initial();
  @include mixin-mat-input-state-hovered();
  @include mixin-mat-input-state-filled();
  @include mixin-mat-input-state-disabled();
  @include mixin-mat-input-state-error();
  @include mixin-mat-input-state-readonly();
  @include mixin-mat-input-state-frameless();
}

@mixin mixin-mat-input-state-initial {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-form-field-infix {
      border-top: none;
    }

    &.mat-mdc-form-field {
      & label {
        color: map-get($color, gunmetal-grey);
      }
    }

    & .mat-mdc-input-element {
      display: inline-block;
      width: 100%;
      outline: none;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid map-get($color, light-grey);
      padding-right: 32px !important;
    }

    & .mat-mdc-form-field-icon-suffix button {
      color: map-get($color, battleship-grey);
    }

    & .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: map-get($color, gunmetal-grey);
    }
  }
}

@mixin mixin-mat-input-state-hovered {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    &.mat-mdc-form-field .mat-mdc-input-element:hover {
      border: 1px solid map-get($color, battleship-grey);
    }

    &.mat-mdc-form-field:hover .label {
      color: map-get($color, jet);
      font-size: 16px;
    }
  }
}

@mixin mixin-mat-input-state-filled {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-input-element {
      &.ng-valid:not(.ng-pristine) {
        border: 1px solid map-get($color, jet);
        color: map-get($color, black);

        &:hover {
          border: 1px solid map-get($color, battleship-grey);
          color: map-get($color, jet);
        }
      }
    }
  }
}

@mixin mixin-mat-input-state-disabled {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    &.mat-form-field-disabled {
      .mdc-text-field--disabled {
        background-color: inherit;
      }

      .mat-mdc-input-element[readonly='true'] {
        &,
        &:hover {
          border: 1px solid map-get($color, light-grey);
          color: map-get($color, battleship-grey);

          & ~ .mat-mdc-floating-label {
            color: map-get($color, battleship-grey);
          }
        }
      }
    }

    & .mat-mdc-form-field-icon-suffix button {
      color: map-get($color, battleship-grey);
    }
  }
}

@mixin mixin-mat-input-state-error {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-form-field-error-wrapper {
      padding: 0 12px;

      & .mat-mdc-form-field-error {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }
    }

    & .mat-mdc-input-element {
      &.ng-invalid.ng-touched {
        border: 1px solid map-get($vi-material-colors, error);

        &:hover {
          border: 1px solid map-get($vi-material-colors, error);
        }
      }
    }
  }
}

@mixin mixin-mat-input-state-readonly {
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    &.mat-mdc-form-field {
      // Read only input which contains a value
      .mat-mdc-input-element[readonly='true'] {
        background-color: map-get($color, cultured-light);
        border: 1px solid map-get($color, cultured-light);
        color: map-get($color, gunmetal-grey);
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
          border: 1px solid map-get($color, cultured-light);
          color: map-get($color, gunmetal-grey);
        }
      }

      .mat-mdc-floating-label:has(+ .mat-mdc-input-element[readonly='true']) {
        color: map-get($color, gunmetal-grey) !important;
      }
    }
  }
}

@mixin mixin-mat-input-state-frameless {
  [mat-input-frameless] .mat-mdc-input-element {
    border: 1px transparent !important;
  }
  [mat-input-frameless]
    .mat-mdc-input-element:not(.mat-input-element-empty)
    .mat-input-element-value {
    margin-right: 8px;
    max-width: inherit;
    width: auto;
  }
  [mat-input-frameless] .mat-mdc-input-element[readonly='true'] {
    width: 100%;
  }
}
