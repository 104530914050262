@import '../../variables';

@mixin mixin-mat-select-states {
  @include mixin-mat-select-state-initial();
  @include mixin-mat-select-state-hovered();
  @include mixin-mat-select-state-filled();
  @include mixin-mat-select-state-disabled();
  @include mixin-mat-select-state-error();
  @include mixin-mat-select-state-frameless();
}

@mixin mixin-mat-select-state-initial {
  [mat-select-height-small],
  [mat-select-height-medium],
  [mat-select-height-large] {
    & .mat-mdc-form-field-infix {
      border-top: none;
    }

    &
      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
      .mat-mdc-form-field-infix {
      padding: 0;
    }

    & .mat-mdc-select {
      font-size: 16px;
      display: inline-block;
      width: 100%;
      outline: none;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid map-get($color, light-grey);
      & .mat-mdc-select-arrow {
        color: map-get($color, davys-grey);
      }
    }
  }

  [mat-select-height-small],
  [mat-select-height-medium],
  [mat-select-height-large],
  [mat-select-frameless] {
    & .mat-mdc-form-field-flex .mat-mdc-floating-label {
      color: map-get($color, davys-grey);
    }
  }
}

@mixin mixin-mat-select-state-hovered {
  .mat-mdc-form-field:hover {
    .mat-mdc-select {
      border: 1px solid map-get($color, battleship-grey);

      &:not(.ng-invalid) .mat-mdc-select-arrow {
        color: map-get($color, jet);
      }
    }

    .mat-mdc-floating-label {
      color: map-get($color, jet) !important;
      font-size: 16px;
    }
  }
}

@mixin mixin-mat-select-state-filled {
  .mat-mdc-select {
    &.ng-valid:not(.ng-pristine) {
      border: 1px solid map-get($color, jet);

      & .mat-mdc-select-trigger {
        color: map-get($color, black);
      }

      & .mat-mdc-select-arrow {
        color: map-get($color, black);
      }

      &:hover {
        border: 1px solid map-get($color, battleship-grey);
        & ~ .mat-mdc-floating-label {
          color: map-get($color, jet);
        }
        & mat-mdc-select-trigger {
          color: map-get($color, jet);
        }
      }
    }
  }
}

@mixin mixin-mat-select-state-disabled {
  .mat-mdc-form-field-type-mat-select.mat-form-field-disabled {
    .mdc-text-field--disabled {
      background-color: inherit;
    }

    // Disabled select which contains a value
    .mat-mdc-select {
      background-color: map-get($color, cultured-light);
      border: 1px solid map-get($color, cultured-light);

      & mat-select-trigger {
        color: map-get($color, battleship-grey);
      }

      & .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
        color: map-get($color, battleship-grey);
      }

      &:hover {
        border: 1px solid map-get($color, cultured-light);

        & .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
          color: map-get($color, battleship-grey);
        }

        & mat-select-trigger {
          color: map-get($color, battleship-grey);
        }
      }
    }

    .mat-mdc-floating-label {
      & mat-label {
        color: map-get($color, battleship-grey);
      }
    }
  }
}

@mixin mixin-mat-select-state-error {
  .mat-mdc-form-field-error-wrapper {
    padding: 0 12px;

    & .mat-mdc-form-field-error {
      color: map-get($vi-material-colors, error);
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
    }
  }

  .mat-mdc-select {
    &.ng-invalid.ng-touched {
      border: 1px solid map-get($vi-material-colors, error);
      & mat-select-trigger {
        color: map-get($color, jet);
      }
      & .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
        color: map-get($color, jet);
      }

      &:hover {
        border: 1px solid map-get($vi-material-colors, error);
        & .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
          color: map-get($color, black);
        }
        & mat-select-trigger {
          color: map-get($color, black);
        }
      }
    }
  }
}

@mixin mixin-mat-select-state-frameless {
  [mat-select-frameless] .mat-mdc-select {
    border: 1px transparent !important;

    .mat-mdc-select-arrow-wrapper {
      padding-right: 8px;
    }
  }

  [mat-select-frameless] .mat-mdc-select:not(.mat-mdc-select-empty) {
    .mat-mdc-select-value {
      margin-right: 8px;
      max-width: inherit;
    }
  }

  [mat-select-frameless] .mat-mdc-select {
    padding: 7px 19px;

    .mat-mdc-select-value {
      width: 100%;
    }
  }
}

// since angular 14 height 1.125em is applied automatically
.mat-mdc-select-trigger {
  height: initial;
}
