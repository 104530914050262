@import '../variables';
@import './button/button-styles.scss';

body {
  @include mixin-button-bounce();

  .mat-mdc-button:not([matsuffix]),
  .mat-mdc-raised-button:not([matsuffix]),
  .mat-mdc-unelevated-button:not([matsuffix]),
  .mat-mdc-outlined-button:not([matsuffix]),
  .mat-mdc-button:not([matsuffix]),
  .mat-mdc-raised-button:not([matsuffix]),
  .mat-mdc-unelevated-button:not([matsuffix]),
  .mat-mdc-outlined-button:not([matsuffix]) {
    line-height: 40px;
    padding: 0 2 * $unit;
    font-weight: 600;
    font-size: 16px;
    transition: background-color $transition-time, color $transition-time,
      border-color $transition-time;
    border-radius: $button-radius;

    &.no-uppercase {
      text-transform: initial;
    }

    &.mat-mdc-icon-button {
      padding: 0;
    }

    &.large {
      line-height: 40px;
      padding: 0 3 * $unit;

      &.mat-mdc-icon-button {
        padding: 0;
      }
    }

    &[disabled] {
      pointer-events: none;
      box-shadow: none;
    }

    &.mat-button--edgy {
      border-radius: $button-radius-low;
    }

    // TODO: Add outer border only if focused with tab key
    // &:focus {
    //   margin: 0;

    //   &::after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     border-radius: 40px;
    //     top: -4px;
    //     bottom: -4px;
    //     left: -4px;
    //     right: -4px;
    //     border: 2px solid map-get($color, light-grey);
    //   }
    // }

    mat-icon ~ span {
      margin-left: 5px;
    }

    span ~ mat-icon {
      margin-left: 5px;
    }
  }

  .mat-mdc-button:not([matsuffix]) {
    &.mat-accent {
      color: map-get($color, white);
    }

    &:hover {
      color: map-get($color, jet);
      background-color: map-get($color, cultured-light);

      &.mat-accent {
        color: map-get($color, white-smoke);
        background-color: map-get($color, jet);
      }
    }

    &:active {
      color: map-get($color, jet);
      background-color: map-get($color, white-smoke);

      &.mat-accent {
        color: map-get($color, gainsboro);
        background-color: map-get($color, eerie-black);
      }
    }

    &[disabled] {
      color: map-get($color, light-grey);

      &.mat-accent {
        color: map-get($color, davys-grey);
      }
    }
  }

  .mat-mdc-raised-button:not([matsuffix]),
  .mat-mdc-unelevated-button:not([matsuffix]),
  .mat-mdc-raised-button:not([matsuffix]) {
    box-shadow: none;

    &.mat-accent {
      background-color: map-get($color, white);
    }

    &:hover {
      background-color: map-get($color, davys-grey);

      &.mat-accent {
        background-color: map-get($color, white-smoke);
      }
    }

    &:active {
      background-color: map-get($color, jet);

      &.mat-accent {
        background-color: map-get($color, gainsboro);
      }
    }

    &:focus {
      background-color: map-get($color, black);

      &.mat-accent {
        background-color: map-get($color, white-smoke);
      }
    }

    &[disabled] {
      color: map-get($color, battleship-grey);
      background-color: map-get($color, white-smoke);

      &.mat-accent {
        color: map-get($color, white-smoke);
      }

      &.mat-warn {
        color: map-get($color, battleship-grey);
        background-color: map-get($color, white-smoke);
      }

      &.mat-primary {
        color: map-get($color, battleship-grey);
        background-color: map-get($color, white-smoke);
      }
    }
  }

  .mat-mdc-outlined-button:not([matsuffix]),
  .mat-mdc-outlined-button:not([matsuffix]) {
    border-color: map-get($color, light-grey);

    &.mat-accent {
      color: map-get($color, white);
      border-color: map-get($color, white);
    }

    &.mat-warn {
      border-color: map-get($color, battleship-grey);
    }

    &.mat-primary {
      border-color: map-get($color, black);
    }

    &:hover {
      border-color: map-get($color, davys-grey);
      color: map-get($color, davys-grey);
      background-color: map-get($color, cultured-light);

      &.mat-accent {
        border-color: map-get($color, white);
        color: map-get($color, white);
        background-color: map-get($color, jet);
      }
    }

    &[disabled] {
      border-color: map-get($color, light-grey);
      color: map-get($color, light-grey);

      &.mat-accent {
        border-color: map-get($color, davys-grey);
        color: map-get($color, davys-grey);
      }
    }
  }

  .vi-button + .vi-button {
    margin-left: $unit;
  }

  .mat-icon {
    overflow: inherit;
  }

  .mat-mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > *[role='img'] {
      width: 24px;
      height: 24px;
      font-size: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .mat-mdc-button-touch-target {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .mdc-button__ripple {
    display: none;
  }
}

// to be removed with global styles for all buttons when https://jira.viessmann.com/browse/VIG-12450 is done
.close-btn-overlay,
.back-btn-overlay {
  min-width: 110px !important;
  height: 48px !important;
}
