@import '../variables';

body {
  .mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
    margin: 0;
  }
  .mat-mdc-snack-bar-container {
    padding: 0;
    margin-bottom: 116px;
    width: 660px;
    max-width: 660px;
    border-radius: $radius;
    box-shadow: $snack-bar-shadow;

    @media (max-width: 660px) {
      margin: 0;
      width: 100vw;
      max-width: 100vw;
    }
  }
}
