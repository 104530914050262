@import '../../variables';

@mixin mixin-mat-select-medium-height {
  [mat-select-height-medium] {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 20px;
    }

    .mat-mdc-select {
      padding: 15px 12px;
    }
  }
}
