@import '../variables';

$actions-background-color: map-get($color, cultured);

body {
  .mat-mdc-card {
    font-family: inherit;
    padding: 3 * $unit;
    color: inherit;
    border-radius: $radius;

    .mat-mdc-card-header {
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    .mat-mdc-card-title {
      margin-bottom: 2 * $unit;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.22;
    }
    .mat-mdc-card-header-text {
      margin: 0;
    }

    .mat-mdc-card-content {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 24px;
      padding: 0;

      &:first-child {
        padding: 0;
      }
    }

    .mat-mdc-card-actions {
      padding: 0;
      margin: 0;

      button,
      .mat-mdc-button,
      .mat-mdc-unelevated-button,
      .mat-mdc-raised-button,
      .mat-mdc-outlined-button {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    @include media-down(small) {
      padding: 2 * $unit;
    }
  }

  .mat-mdc-card > :last-child:not(.mat-mdc-card-actions) {
    margin-bottom: 0;
  }
}
