@import '../common';

body {
  .mat-toolbar.mat-warn {
    background: map-get($color, viorange);
    color: map-get($color, white);
  }

  .mat-toolbar.mat-accent {
    background: map-get($color, white);
    color: map-get($color, gunmetal);
  }

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    @include media-down(xsmall) {
      padding: 0;
    }
  }
}
