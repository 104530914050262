@import '../variables';

body {
  & .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: inherit;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: inherit;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding: 0.2em 0;
  }

  .mat-mdc-form-field .mat-mdc-floating-label {
    color: map-get($color, gunmetal-grey);
  }
}
