@mixin mixin-button-bounce {
  [button-bounce].mat-primary {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    span {
      display: inline-block;
      width: max-content;
      .mat-button-wrapper {
        width: 100%;
      }
      mat-icon {
        vertical-align: middle;
      }
    }
  }

  [button-bounce-off].mat-primary span {
    .mat-button-wrapper {
      width: 100%;
    }
    mat-icon {
      vertical-align: middle;
    }
  }
}
