@import '../variables';

body {
  .mat-mdc-slide-toggle {
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 32px;

    &.mat-mdc-slide-toggle-checked:not(.disabled) {
      .mdc-label {
        font-weight: 700;
      }
    }
    .mdc-label {
      font-size: 16px;
    }

    &.disabled,
    &.deactivated {
      pointer-events: none;

      .mdc-switch__handle {
        pointer-events: none;
      }
    }

    .mdc-switch {
      width: 48px;
      height: 24px;
      border-radius: 32px;
      background-color: map-get($color, light-grey);
      .mdc-switch__track {
        &:before,
        &:after {
          background-color: map-get($color, light-grey) !important;
        }
      }
    }
    .mdc-switch--disabled {
      opacity: 0.38;
    }
    .mdc-switch__handle {
      width: 20px;
      height: 20px;
      top: 2px;
      transform: translate3d(2px, 0, 0);
      &:before,
      &:after {
        background-color: map-get($color, white) !important;
      }
    }

    &.mat-mdc-slide-toggle-checked {
      .mdc-switch {
        background-color: map-get($color, vigreen);
      }
      .mdc-switch__track {
        &:before,
        &:after {
          background-color: map-get($color, vigreen) !important;
        }
      }
      .mdc-switch__handle {
        transform: translate3d(-2px, 0, 0);
        ::before,
        ::after {
          background-color: map-get($color, white);
        }
      }
    }
  }

  // since angular 14 there is extra shadow on toggle
  .mdc-switch__ripple {
    opacity: 0;
  }
}
