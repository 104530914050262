@import '../../variables';

@mixin mixin-mat-input-decorations {
  // Description under Mat-Input
  [mat-input-top-label] {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-input-element ~ [mat-input-description] {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      left: 12px;
      top: 2px;
    }
  }

  // Description disabled
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-input-element[readonly='true'] ~ [mat-input-description],
    & .mat-mdc-input-element[disabled] ~ [mat-input-description] {
      color: map-get($color, battleship-grey);
    }
  }

  // Description filled
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-input-element.ng-valid:not(.ng-pristine) ~ [mat-input-description] {
      color: map-get($color, jet);
    }
  }

  // Suffix
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    & .mat-mdc-form-field-icon-suffix {
      padding: 0 0 6px 6px;
    }
  }

  [mat-input-height-small] {
    & [mat-suffix-sticky] {
      position: absolute;
      user-select: none;
      cursor: text;
      left: 13px;
      top: 11px;
      white-space: pre;
      pointer-events: none;

      & > :first-child {
        opacity: 0;
        user-select: none;
        pointer-events: none;
      }
    }
  }

  //Mat-Input Icon
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    .mat-mdc-form-field-icon-suffix {
      button,
      .mat-icon,
      .mat-mdc-focus-indicator,
      .mat-mdc-button-touch-target {
        position: absolute;
        height: 16px;
        width: 16px;
        padding: 0;
      }

      .mat-mdc-button-ripple,
      .mat-mdc-button-persistent-ripple {
        display: none;
      }

      .mat-datepicker-toggle button {
        position: absolute;
        vertical-align: baseline;
        right: -2px;
        top: -60px;
      }

      :not(.mat-datepicker-toggle) button {
        position: fixed;
        vertical-align: baseline;
        bottom: 0;
        right: -5px;
      }

      .mat-icon {
        font-size: 14px;
        top: 0;
        left: 0;
      }
    }
  }

  [mat-input-height-small] .mat-mdc-form-field-icon-suffix button {
    top: -8px;
    position: absolute;
    right: 1px;
  }

  [mat-input-height-medium] .mat-mdc-form-field-icon-suffix button {
    position: absolute;
    right: 1px;
    top: -4px;
  }

  [mat-input-height-large] .mat-mdc-form-field-icon-suffix button {
    position: absolute;
    right: 1px;
    top: -4px;
  }

  [mat-input-height-large-label] .mat-mdc-form-field-icon-suffix button {
    position: absolute;
    right: 1px;
    top: -4px;
  }

  // Mat-Input Underline
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    .mdc-line-ripple {
      display: none;
    }
  }

  // Mat-Input Label
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large] {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      -webkit-transform: none;
      transform: none;
      padding-top: 0;
      margin-left: 12px;
    }

    &.mat-mdc-form-field-has-icon-suffix label,
    &.mat-focused label {
      display: none;
    }
  }

  // Mat-Input Text field
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    .mdc-text-field {
      padding: 0;
    }
  }

  // Mat-Input Font size
  [mat-input-height-small],
  [mat-input-height-medium],
  [mat-input-height-large],
  [mat-input-height-large-label] {
    &.mat-mdc-form-field label,
    .mat-mdc-input-element {
      font-size: 16px;
      font-weight: 400;
    }
  }

  // Mat-Input Panel
  .mat-mdc-input-element-panel {
    border-radius: $radius;
  }
}
