.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-drop-list-dragging {
  cursor: grabbing !important;
  z-index: 3;
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  display: none;
}

.cdk-drop-list {
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
  transition: box-shadow 0.3s ease-in-out;

  &.cdk-drag-preview {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
    transition: box-shadow 0.3s ease-in-out, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.cdk-drag-animating {
    transition: transform 400ms cubic-bezier(0, 0, 0.2, 1);
  }

  &:active {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
    transition: box-shadow 0.3s ease-in-out, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  }

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
    transition: box-shadow 0.3s ease-in-out;
  }
}

.cdk-drag {
  border-radius: 9px;
  background-color: map-get($color, white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
  transition: box-shadow 0.3s ease-in-out;

  &:active {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.08), inset 0 1px 0 map-get($color, gainsboro);
    transition: box-shadow 0.3s ease-in-out, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  }
}
