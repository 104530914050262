$vi-black: (
  50: #fafafa,
  100: #f0f0f0,
  200: #dedede,
  300: #d0d0d0,
  400: #858585,
  500: #545454,
  600: #404040,
  700: #353535,
  800: #242424,
  900: #000000,
  A100: #f6f6f6,
  A200: #dedede,
  A400: #d0d0d0,
  A700: #353535,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// TODO: Delete unused palettes when redesign is finished
// v-black and vi-light will stay
$vi-primary: (
  50: #e5efff,
  100: #bfd7ff,
  200: #94bcff,
  300: #69a1ff,
  400: #498dff,
  500: #2979ff,
  // main blue color
  600: #2471ff,
  700: #1f66ff,
  800: #195cff,
  900: #0f49ff,
  A100: #ffffff,
  A200: #f7f9ff,
  A400: #c4d0ff,
  A700: #abbcff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$vi-secondary: (
  50: #e6faef,
  100: #c1f2d8,
  200: #98eabe,
  300: #6fe1a3,
  400: #50da90,
  500: #31d47c,
  600: #2ccf74,
  700: #25c969,
  800: #1fc35f,
  900: #13b94c,
  A100: #eafff0,
  A200: #b7ffcd,
  A400: #84ffa9,
  A700: #6aff98,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$vi-accent: (
  50: #e5e6e9,
  100: #bfc2c9,
  200: #9599a5,
  300: #6a7081,
  400: #4a5166,
  500: #2a324b,
  600: #252d44,
  700: #1f263b,
  800: #191f33,
  900: #0f1323,
  A100: #6481ff,
  A200: #3158ff,
  A400: #002ffd,
  A700: #002be4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$vi-warn: (
  50: #fbe3e3,
  100: #f5b8b8,
  200: #ee8989,
  300: #e75a5a,
  400: #e23636,
  500: #dd1313,
  600: #d91111,
  700: #d40e0e,
  800: #cf0b0b,
  900: #c70606,
  A100: #fff0f0,
  A200: #ffbdbd,
  A400: #ff8a8a,
  A700: #ff7070,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$vi-light: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #000000,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #353535,
    400: #353535,
    500: #353535,
    600: #353535,
    700: #353535,
    800: #353535,
    900: #353535,
    A100: #ffffff,
    A200: #353535,
    A400: #353535,
    A700: #353535,
  ),
);

$vi-dark: (
  50: #e5e6e9,
  100: #bfc2c9,
  200: #9599a5,
  300: #6a7081,
  400: #4a5166,
  500: #2a324b,
  600: #252d44,
  700: #1f263b,
  800: #191f33,
  900: #0f1323,
  A100: #6481ff,
  A200: #3158ff,
  A400: #002ffd,
  A700: #002be4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$vi-chart-colors: (
  01: #ff9079,
  02: #ff7f64,
  03: #ff6545,
  04: #d74227,
  05: #af1809,
  06: #880000,
  07: #650000,
  08: #5a0000,
  09: #4c0000,
);

$vi-validation-colors: (
  valid-text: #448d20,
  valid-background: #effcd2,
  invalid-text: #a4201d,
  invalid-background: #fde9d7,
);

$vi-material-colors: (
  error: #e05132,
);

// TODO: Delete unused colors when redesign is finished
$color: (
  // Viessmann colors
  viorange: #ff3e17,
  viyellow: #f9bd5f,
  vigreen: #75c41b,
  // Others colors
  snow: #fffcfa,
  early-dawn: #fffae6,
  azureish-white: #e1e5ee,
  baby-powder: #fafafa,
  baby-powder2: #fdfff9,
  baby-powder3: #fffef8,
  cultured: #f3f4f5,
  solitude: #eeeff1,
  cosmic-latte: #fff8e8,
  quartz: #f9fafd,
  lavender-web: #e0e2ec,
  alice-blue: #ebeff7,
  ghost-white: #f6f8fc,
  gainsboro: #dedede,
  link-water: #bdc2d1,
  lavender-grey: #c7ccdb,
  silver-sand: #bfc2c9,
  silver-chalice: #aaaaaa,
  gunmetal-grey: #767b91,
  granite-gray: #666666,
  black-coral: #5e6574,
  space-cadet: #303955,
  gunmetal: #2a324b,
  sonic-silver: #727272,
  light-salmon: #f5a883,
  antique-white: #fde9d6,
  gold-fusion: #8a7c55,
  selective-yellow: #feb913,
  liver-dogs: #b36f01,
  aero-blue: #c9fbf1,
  celeste: #94f8ec,
  fluorescent-blue: #5deae4,
  dark-turquoise: #35cfd5,
  pacifik-blue: #00a4ba,
  teal-blue: #00809f,
  blue-sapphire: #006085,
  indigo-dye: #00456b,
  prussian-blue: #003259,
  periwinkle: #c3d6fc,
  periwinkle-crayola: #c8d9fb,
  french-sky-blue: #87acf7,
  blue-jeans: #03a6ff,
  blue-crayola: #3372f1,
  azure: #4981f2,
  cobalt-blue: #0d47a1,
  royal-blue: #3e7af6,
  melon: #fdbeb1,
  tangerine-cream: #ff9f8b,
  vermilion: #e32700,
  lava: #d0021b,
  maximum-red: #dd1313,
  cinnabar: #e05132,
  golden-gate-bridge: #c03424,
  golden-gate-bridge-light: #fffcfa,
  rufous: #a71c00,
  white: #ffffff,
  white-weak: rgba(255, 255, 255, 0.49),
  white-opacity: rgba(255, 255, 255, 0.87),
  black: #000000,
  eerie-black: #242424,
  jet: #353535,
  davys-grey: #545454,
  battleship-grey: #858585,
  light-grey: #d0d0d0,
  cultured-light: #f6f6f6,
  white-smoke: #f0f0f0,
  floral-white: #fffcf5,
  floral-white2: #fffdf2,
  black-weak: rgba(0, 0, 0, 0.49),
  black-opacity: rgba(0, 0, 0, 0.87),
  black-weak-opacity: #0000001f,
  caribbean-green: rgb(0, 204, 146),
  green-ryb: #5ba813,
  dark-green: #23a03e,
  eclipse: #404040,
  nyanza: #effcd0,
  twilight-blue: #fdfff9,
  inchworm: #bfed72,
  slimy-green: #458d0d,
  apple-green: #0eb301,
  apple-green-light: #fcfff9,
  china-ivory: #fef5cc,
  construction-yellow: #ffb100,
  construction-yellow-light: #fffff8,
  honey-yellow: #f9ab02,
  orange-crayola: #fdd766,
  raichu-orange: #f7ad37,
  cosmic-latte2: #fffae6,
  ruby-red: #a11c19,
  pearl-bush: #dee3eb,
  rose-frost: #ffece8,
  mercury: #0000008a,
  seasalt: #f6f6f6,
  doeskin: #fff3e5,
  dreamless-sleep: #111111
);

// variables
$unit: 8px;
$radius: 2px;
$button-radius: 40px;
$button-radius-low: 5px;
$transition-time: 0.15s;
$shepherd-padding: 16px;

// shadows
$panel-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.1), 0 0.5px 2px 0 rgba(0, 0, 0, 0.07);
$card-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.1);

$shadow-1: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
$shadow-2: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.07);
$shadow-3: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$shadow-4: 0 1px 2px 0 rgba(0, 0, 0, 0.01);

$shadow-card: 0 4px 8px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08),
  0 1px 2px rgba(0, 0, 0, 0.08), inset 0 1px 0 #dedede;

$button-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
$button-shadow-2: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$button-hover-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$active-button-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
$snack-bar-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.25);
$toast-shadow: $snack-bar-shadow;
