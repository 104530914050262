@import 'src/projects/ui/styles/common';

.datepicker-dialog {
  mat-dialog-container {
    border-radius: 8px;
  }
}

.barcode-scanner-dialog {
  width: 100vw !important;
  height: 100% !important;
  max-width: 100vw !important;
  max-height: 100% !important;
  position: fixed !important;
  top: 0;
  left: 0;
}

.gateway-invitation-list-dialog {
  @include media-down(xsmall) {
    max-width: calc(100vw - 12px) !important;
    width: calc(100vw - 12px) !important;
  }
}

.periods-dialog,
.select-days-dialog,
.installer-registration-dialog,
.user-registration-dialog,
.datepicker-dialog,
.change-password-modal,
.heating-circuit-ignored-dialog,
.missed-hc-assignment-dialog,
.type-code-dialog,
.commissioning-add-device-dialog,
.commissioning-edit-room-dialog,
.commissioning-add-room-dialog,
.commissioning-manage-room-dialog,
.commissioning-all-added-devices-dialog,
.commissioning-all-added-rooms-dialog,
.documents-updated-dialog,
.refrigerant-circuit-history-dialog,
.edit-serial-number-dialog,
.installations-announcement-banner,
.firmware-update-dialog,
.cancel-booking-dialog,
.zigbee-topology-dialog,
.notification-settings-dialog,
.snapshot-dialog,
.add-time-phase-dialog,
.commissioning-add-room-standalone-modal,
.app-tutorials-modal,
.commissioning-modal {
  max-width: calc(100vw - 12px) !important;
  max-height: 98vh !important;

  @include media-down(xsmall) {
    width: calc(100vw - 12px) !important;
  }
}

.commissioning-modal {
  min-height: 546px;

  mat-card {
    padding: 0 !important;
  }

  mat-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  mat-card-header {
    padding: 24px 24px 0 24px !important;
  }

  mat-card-content {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 24px !important;
    margin: 0 !important;
    position: relative;
    min-height: 378px;
    z-index: 10;
  }

  mat-card-actions {
    padding: 24px !important;
    margin: 0 !important;
  }
}

.snapshot-dialog {
  @include media-down(xsmall) {
    position: fixed !important;
    max-height: calc(100vh - 80px) !important;
    top: 60px;
  }
}

@include media-down(small) {
  .energy-historical-data-modal {
    max-width: 100% !important;
    overflow-y: scroll;
  }
}

.block-modal-ui {
  .post-modal-loader {
    display: block !important;
    position: absolute;
    z-index: 10;
  }

  * {
    pointer-events: none !important;
    cursor: default;
  }
}

.center-modal {
  .cdk-global-overlay-wrapper [class^='cdk-overlay-'] {
    margin-left: 354px; // black side panel width
  }
}

.shepherd-modal {
  width: 464px;
  max-width: none;
  min-height: 190px;
  padding: $shepherd-padding;

  @include media-down(xsmall) {
    max-width: calc(100vw - 12px) !important;
    width: calc(100vw - 12px) !important;
  }

  .shepherd-content {
    word-break: break-word;

    .shepherd-header {
      background: transparent;
      padding: 0;
      flex-direction: column;
      min-height: 26px;
      .shepherd-title {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
        color: map-get($color, dreamless-sleep);
        margin-right: auto;
        max-width: calc(100% - 40px);
      }
      .shepherd-cancel-icon {
        color: map-get($color, dreamless-sleep);
        font-size: 32px;
        position: absolute;
        top: 10px;
        right: 20px;
        font-weight: 300;
      }
    }
    .shepherd-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: map-get($color, dreamless-sleep);
      padding: 0;
      margin: 8px 0;
      min-height: 76px;
    }
    .shepherd-footer {
      position: static;
      right: $shepherd-padding;
      bottom: $shepherd-padding;
      padding: 0;
      height: 48px;
      button {
        height: 48px;
        min-width: 108px;
        padding: 13px 20px;
        border-radius: 50px;
        margin-left: 5px;
        margin-right: 5px;
        &:only-child {
          margin: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  app-shepherd-card ~ .shepherd-content {
    .shepherd-text {
      min-height: 100px;
    }
  }

  .shepherd-arrow {
    height: 24px;
    width: 24px;
    &::before {
      width: 24px;
      height: 24px;
      background-color: map-get($color, white) !important;
    }
  }

  &.shepherd-bottom-left {
    left: 50px !important;
    bottom: 50px !important;
    top: auto !important;
    right: auto !important;
    transform: none !important;

    @include media-down(small) {
      left: 50% !important;
      top: 50% !important;
      bottom: auto !important;
      right: auto !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

.shepherd-grid-modal {
  width: 322px;
  max-width: 322px;
  min-height: 389px;
  padding: 0;
  .shepherd-content {
    padding: $shepherd-padding;
    .shepherd-header {
      .shepherd-cancel-icon {
        color: map-get($color, white);
        font-size: 32px;
        position: absolute;
        top: 6px;
        right: 14px;
        font-weight: 300;
      }
    }
  }
}

.shepherd-bubble-modal {
  .shepherd-header {
    display: flex;
    align-items: self-start;
    width: calc(100% - 32px);

    .shepherd-cancel-icon {
      color: map-get($color, white);
      font-size: 32px;
      position: absolute;
      top: 6px;
      right: 14px;
      font-weight: 300;
    }
  }
}

// block interaction with descending children
.shepherd-modal-target.shepherd-enabled.shepherd-target * {
  pointer-events: none !important;
  user-select: none;
}

// block interaction with page when tutorial is open
.shepherd-tutorial-active {
  & div.cdk-overlay-container * {
    pointer-events: none !important;
    user-select: none;
  }
  & app-root * {
    pointer-events: none !important;
    user-select: none;
  }
}

.shepherd-element[data-popper-placement^='bottom-start'],
.shepherd-element[data-popper-placement^='bottom-end'],
.shepherd-element[data-popper-placement^='bottom'] {
  margin-top: 20px;

  & > .shepherd-arrow {
    top: -11px;
  }
}

.shepherd-element[data-popper-placement^='top-start'],
.shepherd-element[data-popper-placement^='top-end'],
.shepherd-element[data-popper-placement^='top'] {
  margin-bottom: 20px;

  & > .shepherd-arrow {
    bottom: -11px;
  }
}

.shepherd-element[data-popper-placement^='right-start'],
.shepherd-element[data-popper-placement^='right-end'],
.shepherd-element[data-popper-placement^='right'] {
  margin-left: 20px;

  & > .shepherd-arrow {
    left: -11px;
  }
}

.shepherd-element[data-popper-placement^='left-start'],
.shepherd-element[data-popper-placement^='left-end'],
.shepherd-element[data-popper-placement^='left'] {
  margin-right: 20px;

  > .shepherd-arrow {
    right: -11px;
  }
}
