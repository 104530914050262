@import '../variables';

body {
  .mat-mdc-tooltip {
    .mdc-tooltip__surface {
      border-radius: $radius;
      padding: 8px;
      background-color: map-get($color, sonic-silver);
      font-size: 12px;
      max-width: 250px;
    }
  }
}
