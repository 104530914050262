@import '../../variables';

@mixin mixin-mat-select-large-height {
  [mat-select-height-large] {
    .mat-mdc-select {
      padding: 26px 12px 10px 12px;
    }

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 34px;
      margin-left: 12px;
    }

    .mat-mdc-floating-label {
      transition: 0.2s ease-in-out !important;
    }

    .mat-mdc-floating-label.mdc-floating-label--float-above {
      top: 34px;
      padding-top: 0;
      margin-left: 12px;
      z-index: 1;
    }
  }
}
